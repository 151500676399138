.calanderView {
    height: calc(100vh - 140px);
}

.rbc-agenda-event-cell {
    /* var(--cell-color) */
    color: 'green' !important;
}

.rcb-event {
    border: none !important;
    box-sizing: border-box;
    box-shadow: none;
    margin: 0;
    padding: 2px 5px;
    background-color: #3174ad;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    width: 100%;
    text-align: left;
}

.rcb-event1 {
    border: none !important;
    box-sizing: border-box;
    box-shadow: none;
    margin: 0;
    padding: 2px 5px;
    background-color: green;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    width: 100%;
    text-align: left;
}
