@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800&display=swap');

html,
body,
* {
    font-family: 'Inter', sans-serif;
}

.rbc-event-label {
    display: none;
}

// html{
//   font-family: Metropolis-Black !important;
// }

// placeholder fonts
.euiComboBoxPlaceholder {
    font-family: 'Inter', sans-serif;
}

/* NO SELECT + HIGHLIGHT COLOR */
// * {
//   user-select: none;
// }
// *::selection {
//   background: none;
// }
// *::-moz-selection {
//   background: none;
// }

.treeView {
    display: flex;
    height: auto;
    width: 18vw;
    border: 1px solid #d3dae6;
    border-radius: 4px;
    box-shadow: 0 2px 2px -1px rgba(152, 162, 179, 0.3), 0 1px 5px -2px rgba(152, 162, 179, 0.3);
    background-color: #fff;
    // border: '1px solid #d3dae6',
    // borderRadius: '4px',
    // boxShadow: "0 2px 2px -1px rgba(152,162,179,.3), 0 1px 5px -2px rgba(152,162,179,.3)",
    // backgroundColor: '#fff'
}

.treeView_Content {
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    white-space: normal;
    display: inline-block;
    width: 100%;
    hyphens: auto;
    font-size: 14px;
    cursor: pointer;
}

.itemList_Content {
    font-size: 14px !important;
}

.itemList_Delete_Content {
    font-size: 14px !important;
}

.treeView_edit {
    font-size: 14px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;
    color: #006bb4;
    background: transparent;
    cursor: pointer;
    padding-top: 5px !important;
    // padding-right: 25px !important;
}

.treeViewButton {
    background: transparent !important;
    height: 20px !important;
    font-size: 14px !important;
}

@media screen and (max-width: 1600px) {
    .treeView {
        display: flex;
        height: auto;
        width: 15vw;
        border: 1px solid #d3dae6;
        border-radius: 4px;
        box-shadow: 0 2px 2px -1px rgba(152, 162, 179, 0.3), 0 1px 5px -2px rgba(152, 162, 179, 0.3);
        background-color: #fff;
    }

    .treeView_Content {
        overflow-wrap: break-word;
        word-wrap: break-word;
        word-break: break-word;
        white-space: normal;
        display: inline-block;
        width: 100%;
        hyphens: auto;
        font-size: 10px;
        cursor: pointer;
    }

    .itemList_Content {
        font-size: 10px !important;
    }

    .itemList_Delete_Content {
        font-size: 8px !important;
    }

    .treeView_edit {
        font-size: 10px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: row;
        color: #006bb4;
        background: transparent;
        cursor: pointer;
        padding-top: 5px !important;
        // padding-right: 15px !important;
    }

    .treeViewButton {
        background: transparent !important;
        height: 20px !important;
        font-size: 10px !important;
    }
}
.orders-summary-container {
    // calc(100vh - 191px)
    height: calc(100vh - 100px);
    overflow: auto;
}
.order-custom-toast {
    .euiToast--primary {
        border-bottom: 4px solid #275af6 !important;
        border-top: 1px solid #343740 !important;
    }
    .euiToast {
        background-color: #fcfcfc;
    }
    .euiToastHeader {
        display: none;
    }
}
.flex-horizontal-center {
    justify-content: center;
    -webkit-justify-content: center;
}
.flex-vertical-center {
    align-content: center;
    -webkit-align-content: center;
}
.orders-summary-card {
    cursor: pointer;
    .euiToastHeader {
        display: none !important;
    }
}
.order-details-card {
    // calc(100vh - 349px)
    // calc(100vh - 230px)
    max-height: calc(100vh - 135px);
    overflow: auto;
    overflow-x: hidden;
}
.orders-summary-container-past {
    // calc(100vh - 196px)
    height: calc(100vh - 105px);
    overflow: auto;
}
.order-details-card-past {
    // calc(100vh - 155px)
    height: calc(100vh - 142px);
    overflow: auto;
}
.header-logo-img img {
    width: 110px;
    height: auto;
}
.mr-left-10 {
    margin-left: 10px;
}
.mr-left-20 {
    margin-left: 20px;
}
.main-page-height {
    //  calc(100vh - 49px)
    height: calc(100vh - 49px) !important;
    overflow-x: hidden;
    // overflow: hidden;
}

.no-shadow {
    box-shadow: none !important;
    -moz-box-shadow: none !important;
    -o-box-shadow: none !important;
    -webkit-box-shadow: none !important;
}

.no-order-image {
    width: 70px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.cancel-order-button {
    background-color: #ff0000 !important;
    border-color: #ff0000 !important;
}
.mr-top-none {
    margin-top: 0 !important;
}
.custom-badge {
    padding-left: 12px !important;
}
.text-uppercase {
    text-transform: uppercase;
}
.text-justify {
    text-align: justify;
    text-justify: distribute;
}
.border-radius-4 {
    border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
}
.no-order-section {
    position: relative;
    height: 70vh;
}
.no-order-section-child {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.no-border {
    border: none !important;
}
.calendar-icon {
    .euiFormControlLayoutIcons {
        left: 18px !important;
    }
}
.full-screen-flyout {
    width: 100vw !important;
}
.agmark-img {
    width: 18px;
    margin-top: 5px;
    margin-left: 5px;
}
