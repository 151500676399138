.CalendarDay__selected_span {
    background: #275af6 !important;
    color: var(--var-for-theme-date-picker);
    border: 1px solid #79aad9;
}

.CalendarDay:hover {
    background: var(--var-for-theme-date-picker-border) !important;
}

.CalendarDay__selected {
    background: #79aad9 !important;
    color: var(--var-for-theme-date-picker);
}

.CalendarDay__selected:hover {
    background: #275af6 !important;
    color: var(--var-for-theme-date-picker);
    /* color: var(--var-for-theme-date-picker) !important;  */
}

.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
    background: #275af6 !important;
}

.DateInput_input {
    color: var(--var-for-theme-date-picker-fonts);
}

.DateInput_input {
    background: var(--var-for-theme-date-picker);
}

.DateRangePickerInput__withBorder {
    border: 1px solid var(--var-for-theme-date-picker-border);
}

.DateRangePickerInput {
    background-color: var(--var-for-theme-date-picker);
}

.DateRangePickerInput_arrow_svg {
    fill: var(--var-for-theme-date-picker-fonts);
}

.CalendarMonth,
.DayPicker_transitionContainer {
    background: var(--var-for-theme-date-picker);
}

.CalendarDay__default {
    background: var(--var-for-theme-date-picker);
    border: 1px solid var(--var-for-theme-date-picker);
    color: var(--var-for-theme-date-picker-fonts);
}

.DayPickerNavigation_button__default {
    background-color: var(--var-for-theme-date-picker);
}

.CalendarMonthGrid {
    background: var(--var-for-theme-date-picker);
}

.CalendarMonth_caption {
    color: var(--var-for-theme-date-picker-fonts);
}
