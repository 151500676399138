.outleLinkStyle {
    min-height: 200px !important;
    width: 100% !important;
    max-height: calc(100vh - 120px) !important;
    overflow: auto;
}

.outleLinkStyleForItem {
    min-height: 0px !important;
    width: 100% !important;
    max-height: calc(100vh - 120px) !important;
    overflow: auto;
}

.arrowStyle {
    max-height: calc(100vh - 400px) !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
}
