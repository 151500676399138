.availability-switch .euiButtonGroup__buttons {
    border-radius: 45px !important;
}

.availability-switch .euiButtonGroup__buttons label:nth-child(2) {
    cursor: not-allowed;
}

.availability-switch .euiButtonGroup__buttons label:nth-child(1):hover {
    background-color: #bd271e !important;
    opacity: 0.2 !important;
}

.availability-switch .euiButtonGroup__buttons label:nth-child(2):hover {
    background-color: transparent !important;
}

.availability-switch .euiButtonGroup__buttons label:nth-child(3):hover {
    background-color: #00bfb3 !important;
    opacity: 0.2 !important;
}
/* availability-switch */

.availability-switch .euiButtonGroupButton--small {
    border-radius: 50% !important;
    /* height: ; */
    height: 26px !important;
    width: 26px !important;
    align-self: center;
}
