.paymentSectionStyle {
    height: calc(100vh - 205px);
    padding: 9px;
    display: flex;
    flex-direction: column;
    overflow: auto;
}
.paymentSectionInputAreas {
    height: calc(100vh - 500px) !important;
    overflow: auto;
}

.itemDescriptionForTable {
    line-height: 1rem !important;
    overflow: hidden !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 2 !important;
    -webkit-box-orient: vertical !important;
    font-size: 12px !important;
}
.customCellClassForItemPrice {
    display: flex !important;
    flex-direction: row !important;
    justify-content: flex-end !important;
}

.customRowClassForSection {
    height: 24px !important;
    background-color: #f5f7fa !important;
    font-size: 12px !important;
    font-weight: bold !important;
}
.customerClassForRowHeight {
    height: 18px !important;
}
.billingSectionCustomerDetailPipe {
    border: 1px solid white !important;
    margin: 5px 10px !important;
    height: 20px !important;
}
.billingSectionCustomerDetails {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    cursor: pointer !important;
}

.takeawayList {
    width: 100%;
    display: flex !important;
    flex-direction: row !important;
    flex-wrap: wrap !important;
    height: calc(100vh - 190px) !important;
    overflow-x: auto !important;
    align-content: baseline !important;
}

.paymentSection {
    height: calc(100vh - 96px);
}
.addonView {
    height: calc(100vh - 98px);
    background-color: #fafafa !important;
}

.stepText {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 6.5px;
    font-size: 14px !important;
    padding-left: 14px;
    /* font-family: Metropolis-SemiBold !important; */
}
.arrowBlock:after {
    color: var(--my-color-var);
    border-left: 13px solid;
    border-top: 13px solid transparent;
    border-bottom: 13px solid transparent;
    display: inline-block;
    content: '';
    position: absolute;
    right: -13px;
    top: 0px;
}

.arrowBlock:before {
    border-left: 13px solid white;
    border-top: 13px solid transparent;
    border-bottom: 13px solid transparent;
    display: inline-block;
    content: '';
    position: absolute;
    left: 0px;
}

.arrowBlock {
    background-color: var(--my-color-var);
    width: 15%;
    height: 26px;
    display: inline-block;
    position: relative;
}

.homeArrow:after {
    color: #ae27f6;
    border-left: 13px solid;
    border-top: 13px solid transparent;
    border-bottom: 13px solid transparent;
    display: inline-block;
    content: '';
    position: absolute;
    right: -13px;
    top: 0px;
}

.homeArrow:before {
    color: #ae27f6;
    border-right: 13px solid;
    border-top: 13px solid transparent;
    border-bottom: 13px solid transparent;
    display: inline-block;
    content: '';
    position: absolute;
    left: -13px;
}

.homeArrow {
    background-color: #ae27f6;
    width: 15%;
    height: 26px;
    display: inline-block;
    position: relative;
}

.itemDescription {
    line-height: 1rem !important;
    overflow: hidden !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 3 !important;
    -webkit-box-orient: vertical !important;
    height: 3rem !important;
    font-size: 12px !important;
}
.customerInformation {
    /* background-color: #ffffff !important;
    border: 1px solid #d8dadc !important; */
    margin: 0px !important;

    /* 138px */
    height: calc(100vh - 154px) !important;
    /* overflow: auto !important; */
}

.customerForm {
    margin: 0px !important;
    /* 220px */
    height: calc(100vh - 225px) !important;
    overflow: auto !important;
    /* border-bottom: 1px solid black; */
    margin-bottom: 20px !important;
    /* width: 66% !important; */
}

.tableStatusRectangle {
    width: 20px;
    height: 20px;
    border-radius: 4px;
    /* margin-right: 10px !important; */
    margin: 0px 5px !important;
}

.tableList {
    width: 100%;
    display: flex !important;
    flex-direction: row !important;
    flex-wrap: wrap !important;
    /* 198px */
    max-height: calc(100vh - 230px) !important;
    overflow-x: auto !important;
    align-content: baseline !important;
}

.table {
    /* box-shadow: '0 0 15px 0 rgba(0, 0, 0, 0.2)' !important; */
    width: 45% !important ;
    display: flex;
    flex-direction: column;
    padding: 10px 16px;
    border-radius: 3px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
    margin-right: 20px;
    margin-bottom: 22px;
    justify-content: space-between;
    cursor: pointer !important;
}

.copyModal {
    width: 541px !important;
    background-color: #e8e8e8 !important;
    /* font-family: Metropolis !important; */
}

.copyModalBody {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
}

.copyModalFooter {
    background-color: white !important;
    display: flex !important;
    flex-direction: row !important;
    justify-content: flex-end !important;
}

.euiStepHorizontal {
    padding: 1px 1px !important;
}

.euiStepHorizontal:after,
.euiStepHorizontal:before {
    top: 14px !important;
}

.euiStepHorizontal__title {
    font-size: 10px !important;
    margin-top: 1px !important;
}

.euiStepNumber {
    font-size: 10px !important;
    height: 28px !important;
    width: 28px !important;
}

.item {
    box-shadow: '0 0 15px 0 rgba(0, 0, 0, 0.2)' !important;
    width: 29% !important;
}

.btn {
    box-shadow: '0 0 15px 0 rgba(0, 0, 0, 0.2)' !important;
    width: 29.5% !important;
}

.btn:active {
    transform: scale(0.98) !important;
    /* Scaling button to 0.98 to its original size */
    box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24) !important;
    /* Lowering the shadow */
    width: 30% !important;
}

.itemPriceSection {
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
    align-items: center !important;
    margin-top: 10px !important;
}

.itemPrice {
    /* padding: 5px 0px !important; */
    font-size: 20px !important;
}

.itemStock {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    font-size: 10px !important;
    background-color: #f75f00 !important;
    padding: 5px !important;
    border-radius: 3px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2) !important;
}

.itemQuantity {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    font-size: 12px !important;
    background-color: #f75f00 !important;
    padding: 5px !important;
    border-radius: 3px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2) !important;
    width: 22px !important;
    border-radius: 50% !important;
    position: absolute !important;
    bottom: -9px !important;
    right: -9px !important;
    color: white !important;
}

.dotted {
    border: 1px dotted rgba(0, 0, 0, 0.5) !important;
    border-style: none none dotted !important;
    cursor: pointer !important;
}

.POSModeFont {
    /* font-family: 'Metropolis' !important; */
    font-size: 18px !important;
    font-weight: 900 !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: normal !important;
    letter-spacing: 0px !important;
    text-align: center !important;
}

.customerInfo {
    width: 465px !important;
    height: 52px !important;
    padding: 10px 41px 10px 15px !important;
    background-color: #ceffbc !important;
}

.POSLeftSide {
    width: 97%;
    margin-top: 5px;
    border: 1px solid #d3dae6;
    border-radius: 4px;
    padding: 10px;
    height: calc(100vh - 96px);
}

.POSBillingSection {
    overflow-y: 'auto';
    overflow-x: 'hidden';
    display: 'flex';
    flex-direction: 'column';
    height: calc(100vh - 96px);
    /* padding: 0px; */
}
.POSBillingSectionItem {
    /* 415px */
    height: calc(100vh - 382px) !important;
}

.POSBillingSectionWithoutItem {
    /* 270px */
    height: calc(100vh - 245px) !important;
}

.POSBillingSectionItemForDineIn {
    height: calc(100vh - 405px) !important;
}

.POSBillingSectionWithoutItemForDineIn {
    /* 265px */
    height: calc(100vh - 245px) !important;
}

.POSBillingSectionWithoutItemForDineInWithCustomerInfo {
    /* 430px */
    height: calc(100vh - 405px) !important;
}

.POSPaymentSection {
    height: calc(100vh - 220px) !important;
}

.POSItemList {
    /* 228px */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: calc(100vh - 228px);
    /* 138px */
    overflow-x: auto;
    align-content: baseline !important;
    width: '100%';
}

#style-3::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #fdfdfd;
}

#style-3::-webkit-scrollbar {
    width: 6px;
    height: 2.5px;
    background-color: #f5f5f5;
}

#style-3::-webkit-scrollbar-thumb {
    background-color: #9a9a9a;
}
.categoryList {
    display: 'flex';
    flex-direction: 'row';
    overflow-y: 'scroll';
    background-color: '#c57c7c';
}

.categoryList a:hover,
.categoryList a:active {
    background-color: burlywood;
}

.itemListStyle:hover {
    background-color: blue;
}

.tableName {
    font-size: 14px !important;
}

.panelClass {
    width: 70% !important;
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.tableClass {
    width: 25% !important;
    align-items: center;
    padding: 10px;
    height: 100px !important;
}

.panelClassOrderList {
    width: 100% !important;
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.kotRunningGradient {
    background-image: linear-gradient(to right, #ffad00, #f8d59b);
}

.newOrderGradient {
    background-image: linear-gradient(to right, #bb2205, rgba(247, 89, 64, 0.38));
}

.readyOrderGradient {
    background-image: linear-gradient(to right, #2e923e, rgba(61, 199, 190, 0.38));
}

@media screen and (max-width: 1300px) {
    .tableName {
        font-size: 10px !important;
    }

    .panelClass {
        width: 100% !important;
        align-items: center;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    .tableClass {
        width: 30% !important;
        align-items: center;
        padding: 10px;
        height: 100px !important;
    }
}

@media screen and (min-width: 1400px) {
    .table {
        width: 30% !important;
    }
}

/* 1400 */
@media screen and (max-width: 1436px) {
    .item {
        width: 46% !important;
    }

    .btn {
        width: 46% !important;
    }

    .btn:active {
        width: 46% !important;
    }
}

@media screen and (max-width: 1600px) {
    .itemStock {
        font-size: 6px !important;
    }

    .itemPrice {
        font-size: 16px !important;
    }
}

/* 1000 */
@media screen and (max-width: 1200px) {
    .item {
        width: 100% !important;
    }

    .btn {
        width: 100% !important;
    }

    .btn:active {
        width: 100% !important;
    }
}
